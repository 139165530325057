#contact {
    background-color: #fff;
    padding-top: 5%;
    padding-bottom: 200px;
}
#contact > img {
    width: 300px;
    display: block;
    margin: 0 auto;
}
#contact h2 {
    font-size: 96px;
    text-align: center;
    margin: 64px 0;
    color: #211D1D;
}
#contact .container {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}
#contact .container .col {
    flex-basis: 45%;
}
#contact .container h3 {
    font-size: 48px;
    margin-top: 0;
    margin-bottom: 8px;
    color: #211D1D;
}
#contact input,
#contact textarea {
    display: block;
    width: calc(100% - 36px - 36px);
    font-size: 18px;
    padding: 36px;
    border: 2px solid #728C73;
    margin-bottom: 28px;
}
#contact textarea {
    resize: vertical;
}
#contact button {
    width: 100%;
}
#contact a {
    font-size: 18px;
    padding: 36px;
    border: 2px solid #728C73;
    background: #F4FFF5;
    color: #000;
    display: block;
    margin-bottom: 28px;
}
#contact .error {
    color: red;
    font-size: 12px;
}

@media only screen and (max-width: 1000px) {
    #contact h2 {
        font-size: 64px;
    }
    #contact .container h3 {
        font-size: 20px;
    }
    #contact .container {
        flex-direction: column-reverse;
    }
    #contact .container .col {
        margin-bottom: 50px;
    }
}
@media only screen and (max-width: 768px) {
    #contact > img  {
        width: 200px;
    }
    #contact h2 {
        font-size: 32px;
    }
    #contact .container h3 {
        font-size: 22px;
    }
    #contact a,
    #contact input,
    #contact textarea {
        padding: 18px;
        font-size: 14px;
    }
    #contact input,
    #contact textarea {
        width: calc(100% - 18px - 18px);
    }
}
