html, body {
  scroll-behavior: smooth;
  background: #f4fff5;
  margin: 0;
  padding: 0;
  font-family: Shippori Antique B1, sans-serif;
  font-style: normal;
  font-weight: 400;
}

button, input, textarea {
  font-family: Shippori Antique B1, sans-serif;
  font-style: normal;
  font-weight: 400;
}

a {
  text-decoration: none;
}

button {
  color: #fff;
  cursor: pointer;
  background: #728c73;
  border: 0;
  padding: 12px 32px;
  font-size: 20px;
}

nav {
  cursor: pointer;
  z-index: 10;
  background-color: #728c73;
  background-image: url("house1.14ea898e.jpeg");
  background-position: center;
  background-size: cover;
  width: 40%;
  height: 100vh;
  transition: right 1s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -38%;
}

nav.opened {
  transition: right .5s;
  right: 0;
}

nav.opened:hover {
  right: 0;
}

nav:hover {
  right: -37%;
}

nav.opened button.close, nav button.open {
  display: block;
}

nav.opened button.open, nav button.close {
  display: none;
}

nav button {
  cursor: pointer;
  background: #526553;
  width: 32px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -32px;
}

nav button svg {
  width: 32px;
  height: 32px;
}

nav .content {
  text-align: right;
  position: absolute;
  bottom: 64px;
  right: 32px;
}

nav .content a {
  color: #fff;
  font-size: 48px;
  text-decoration: none;
  display: block;
}

nav .content a:hover {
  text-decoration: underline;
}

nav .content .contact-info {
  color: #fff;
  margin-top: 64px;
}

nav .content .contact-info a {
  margin-left: 16px;
  font-size: 16px;
  text-decoration: underline;
  display: inline-block;
}

nav .bg {
  opacity: .9;
  z-index: -1;
  -webkit-user-select: none;
  user-select: none;
  background: #728c73;
  position: absolute;
  inset: 0;
}

#hero {
  padding: 60px 120px;
  position: relative;
}

#hero h1 {
  font-size: 32px;
  display: none;
}

#hero img {
  width: 270px;
  height: auto;
}

#hero h1, #hero h2 {
  color: #728c73;
  margin-top: 0;
  margin-left: -5px;
}

#hero h2 {
  width: 550px;
  margin: 48px 0 70px;
  font-size: 118px;
  line-height: 96px;
}

@keyframes softFloat {
  0%, 100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-12px);
  }
}

#hero .go-down {
  animation: 3s ease-in-out infinite softFloat;
  position: absolute;
  left: 50%;
}

#team {
  min-height: 70vh;
  padding: 12vh 0 5vh;
}

#team > div {
  background: #fff;
  width: 80%;
  min-height: 550px;
  margin: auto;
  display: flex;
  overflow: hidden;
}

#team > div:first-child {
  border-bottom: 2px solid #eee;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

#team > div:last-child {
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

#team .col-text {
  flex-basis: 60%;
  padding: 70px;
}

#team h2 {
  color: #211d1d;
  margin: 0 0 32px;
  font-size: 96px;
}

#team p, #team li {
  color: #483519;
  margin: 0;
  font-size: 18px;
}

#team .col-img {
  background-position: center;
  background-size: cover;
  flex-basis: 40%;
}

#team > div:first-child .col-img {
  background-image: url("tres.24ca77f7.jpg");
}

#team > div:last-child .col-img {
  background-image: url("house1.14ea898e.jpeg");
}

#solutions h2 {
  color: #483519;
  text-align: center;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 96px;
}

#solutions p {
  color: #6e664f;
  text-align: center;
  width: 50%;
  margin: auto auto 64px;
  font-size: 20px;
}

#solutions ul {
  flex-flow: wrap;
  justify-content: center;
  gap: 2%;
  display: flex;
}

#solutions ul li {
  color: #6e664f;
  text-align: center;
  flex-basis: 45%;
  width: 48%;
  margin-bottom: 24px;
  padding: 24px;
  font-size: 40px;
  list-style-type: none;
}

#how h2 {
  color: #211d1d;
  text-align: center;
  font-size: 96px;
}

#how .container {
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  display: flex;
}

#how .container div {
  flex-basis: calc(33.333% - 52px);
  min-height: 400px;
  padding: 24px;
}

#how .container div > span {
  color: #728c73;
  font-size: 90px;
}

#how .container div > h3 {
  color: #483519;
  margin-bottom: 4px;
  font-size: 32px;
}

#how .container div > p {
  color: #728c73;
  font-size: 16px;
}

#how .container div:nth-child(2), #how .container div:nth-child(5) {
  border-left: 2px solid #728c73;
  border-right: 2px solid #728c73;
}

#how .container div:first-child, #how .container div:nth-child(2), #how .container div:nth-child(3) {
  border-bottom: 2px solid #728c73;
}

#founder {
  background-color: #728c73;
  background-image: url("lines-bg.10a415ec.png");
  background-size: cover;
  padding: 64px 0;
}

#founder .content {
  width: 60%;
  margin: auto;
}

#founder h3, #founder p {
  color: #fff;
}

#founder h3 {
  margin-bottom: 0;
  font-size: 64px;
}

#founder p {
  margin-top: 0;
  font-size: 20px;
  line-height: 36px;
}

#founder .center {
  text-align: center;
}

#founder a {
  color: #728c73;
  cursor: pointer;
  background: #fff;
  border: 0;
  padding: 12px 32px;
  font-size: 20px;
  display: inline-block;
}

footer {
  text-align: center;
  background: #f4fff5;
  padding: 100px 0 50px;
}

footer h1 {
  display: none;
}

footer .sm {
  margin: 32px auto;
}

footer .sm a {
  margin: 0 32px;
}

footer .legal {
  color: #bfb598;
  font-size: 16px;
}

footer .zac {
  color: #bbb;
  margin-top: 32px;
  font-size: 12px;
}

@media only screen and (width <= 1000px) {
  #team {
    padding-bottom: 4vh;
  }

  #team > div {
    flex-direction: column;
  }

  #team > div:last-child {
    flex-direction: column-reverse;
    display: flex;
  }

  #team .col-text {
    padding: 20px;
  }

  #team h2 {
    margin-bottom: 16px;
    font-size: 50px;
  }

  #team .col-img {
    flex-grow: 1;
    min-height: 300px;
  }

  #solutions h2 {
    font-size: 42px;
  }

  #solutions p {
    width: 80%;
    font-size: 14px;
  }

  #solutions ul {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    padding-left: 0;
  }

  #solutions ul li {
    text-align: center;
    flex-basis: 100%;
    width: 80%;
    padding: 8px;
    font-size: 28px;
  }

  #how .container div {
    flex-basis: calc(50% - 52px);
  }

  #how .container div:nth-child(2), #how .container div:nth-child(5) {
    border-left: none;
    border-right: none;
  }

  #how .container div:first-child, #how .container div:nth-child(3), #how .container div:nth-child(5) {
    border-right: 2px solid #728c73;
  }

  #how .container div:first-child, #how .container div:nth-child(2), #how .container div:nth-child(3), #how .container div:nth-child(4) {
    border-bottom: 2px solid #728c73;
  }

  #founder .content {
    width: 80%;
  }
}

@media only screen and (width <= 768px) {
  nav {
    width: 80%;
    right: -80%;
  }

  nav:hover {
    right: -80%;
  }

  nav.opened, nav.opened:hover {
    right: 0;
  }

  nav button {
    bottom: unset;
    width: 60px;
    height: 60px;
    left: -60px;
  }

  #hero {
    text-align: center;
    padding: 60px 24px;
  }

  #hero h1 {
    font-size: 24px;
  }

  #hero h2 {
    width: 100%;
    margin: 64px auto;
    font-size: 72px;
    line-height: 78px;
  }

  #hero a {
    display: block;
  }

  #team h2 {
    font-size: 32px;
  }

  #team p {
    font-size: 14px;
  }

  #solutions ul li {
    font-size: 20px;
  }

  #how h2 {
    font-size: 50px;
  }

  #how .container {
    width: 100%;
  }

  #how .container div {
    min-height: 300px;
  }

  #how .container div > span {
    font-size: 60px;
  }

  #how .container div > h3 {
    font-size: 18px;
  }

  #how .container div > p {
    font-size: 14px;
  }

  #founder h3 {
    font-size: 40px;
  }

  #founder p {
    margin-bottom: 16px;
    font-size: 18px;
  }
}

@media only screen and (width <= 500px) {
  #how .container div {
    min-height: unset;
    border-bottom: 2px solid #728c73;
    flex-basis: calc(100% - 52px);
    border-left: none !important;
    border-right: none !important;
  }

  #how .container div:last-child {
    border-bottom: none;
  }

  #how .container div > h3 {
    margin-top: 6px;
    font-size: 32px;
  }

  #how .container div > p {
    font-size: 18px;
  }

  #founder h3 {
    margin-bottom: 16px;
    font-size: 24px;
  }

  #founder p {
    font-size: 14px;
    line-height: 24px;
  }
}

#contact {
  background-color: #fff;
  padding-top: 5%;
  padding-bottom: 200px;
}

#contact > img {
  width: 300px;
  margin: 0 auto;
  display: block;
}

#contact h2 {
  text-align: center;
  color: #211d1d;
  margin: 64px 0;
  font-size: 96px;
}

#contact .container {
  justify-content: space-between;
  width: 80%;
  margin: auto;
  display: flex;
}

#contact .container .col {
  flex-basis: 45%;
}

#contact .container h3 {
  color: #211d1d;
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 48px;
}

#contact input, #contact textarea {
  border: 2px solid #728c73;
  width: calc(100% - 72px);
  margin-bottom: 28px;
  padding: 36px;
  font-size: 18px;
  display: block;
}

#contact textarea {
  resize: vertical;
}

#contact button {
  width: 100%;
}

#contact a {
  color: #000;
  background: #f4fff5;
  border: 2px solid #728c73;
  margin-bottom: 28px;
  padding: 36px;
  font-size: 18px;
  display: block;
}

#contact .error {
  color: red;
  font-size: 12px;
}

@media only screen and (width <= 1000px) {
  #contact h2 {
    font-size: 64px;
  }

  #contact .container h3 {
    font-size: 20px;
  }

  #contact .container {
    flex-direction: column-reverse;
  }

  #contact .container .col {
    margin-bottom: 50px;
  }
}

@media only screen and (width <= 768px) {
  #contact > img {
    width: 200px;
  }

  #contact h2 {
    font-size: 32px;
  }

  #contact .container h3 {
    font-size: 22px;
  }

  #contact a, #contact input, #contact textarea {
    padding: 18px;
    font-size: 14px;
  }

  #contact input, #contact textarea {
    width: calc(100% - 36px);
  }
}

#carousel {
  background: #fff;
}

#carousel h2 {
  text-align: center;
  color: #211d1d;
  font-size: 96px;
}

#carousel h3 {
  text-align: center;
  color: #728c73;
  font-size: 50px;
}

#carousel .image-container {
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 90vh;
  position: relative;
}

#carousel .title-container {
  background-image: url("black-gradient.7af2464e.png");
  background-repeat: repeat-x;
  background-size: auto 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px 5%;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

#carousel .title-container h4 {
  color: #fff;
  flex-grow: 1;
  font-size: 28px;
}

#carousel .title-container button {
  background: none;
  border: none;
}

#carousel .image-nav {
  background: #000;
  padding: 0 5%;
}

#carousel .image-nav button {
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  margin: 32px 16px;
  transition: transform .3s;
  display: inline-block;
  overflow: hidden;
}

#carousel .image-nav button.selected {
  border: 1px solid #fff;
  transform: translateY(-10px);
}

@media only screen and (width <= 992px) {
  #carousel {
    padding-top: 40px;
  }

  #carousel h2 {
    font-size: 40px;
  }

  #carousel h3 {
    margin-bottom: 64px;
    font-size: 24px;
  }

  #carousel .image-container {
    background-size: cover;
    min-height: 70vh;
  }

  #carousel .title-container {
    height: 140px;
    padding: 8px 0;
  }

  #carousel .title-container h4 {
    margin: 0;
    font-size: 16px;
  }

  #carousel .title-container button {
    padding: 8px;
  }

  #carousel .image-nav {
    padding-bottom: 32px;
  }

  #carousel .image-nav button {
    margin: 8px;
  }

  #carousel .image-nav button.selected {
    transform: unset !important;
  }
}

#testimonials {
  background: #fff;
  justify-content: center;
  align-items: center;
  width: 95%;
  display: flex;
}

#testimonials .col-title {
  text-align: center;
}

#testimonials h2 {
  color: #728c73;
  font-size: 64px;
}

#testimonials .col-testimonials {
  flex-basis: 50%;
  max-height: 70vh;
  padding: 5% 0 5% 5%;
  overflow-y: scroll;
}

#testimonials .testimonial {
  color: #fff;
  background-color: #728c73;
  background-image: url("quote.b56b4b3a.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 150px;
  border-radius: 22px;
  margin-bottom: 40px;
  padding: 40px;
}

#testimonials .testimonial h6, #testimonials .testimonial p {
  margin: 0;
  font-size: 16px;
}

#testimonials .testimonial h6 {
  margin-top: 32px;
  font-size: 18px;
}

@media only screen and (width <= 1000px) {
  #testimonials {
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin: auto;
    padding: 0 10%;
  }

  #testimonials .col-testimonials {
    padding: 0;
  }

  #testimonials .testimonial {
    width: 80%;
    margin: 40px auto;
  }
}

@media only screen and (width <= 600px) {
  #testimonials {
    width: 100%;
    padding: 0;
  }

  #testimonials h2 {
    font-size: 48px;
  }

  #testimonials .testimonial {
    padding: 20px;
  }
}

/*# sourceMappingURL=index.4272d2d3.css.map */
