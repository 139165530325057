html, body {
    font-family: "Shippori Antique B1", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: 0;
    padding: 0;
    background: #F4FFF5;
    scroll-behavior: smooth;
}
button, input, textarea {
    font-family: "Shippori Antique B1", sans-serif;
    font-weight: 400;
    font-style: normal;
}
a {
    text-decoration: none;
}

button {
    background: #728C73;
    border: 0;
    color: #FFF;
    font-size: 20px;
    padding: 12px 32px;
    cursor: pointer;
}

nav {
    position: fixed;
    width: 40%;
    height: 100vh;
    background-color: #728C73;
    background-image: url('../assets/house1.jpeg');
    background-size: cover;
    background-position: center;
    right: -38%;
    top: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 10;
    transition: right 1s ease;
}
nav.opened {
    right: 0;
    transition: right 0.5s ease;
}
nav.opened:hover {
    right: 0;
}
nav:hover {
    right: -37%;
}
nav.opened button.close,
nav button.open {
    display: block;
}
nav.opened button.open,
nav button.close {
    display: none;
}
nav button {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -32px;
    width: 32px;
    background: #526553;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
nav button svg {
    width: 32px;
    height: 32px;
}
nav .content {
    position: absolute;
    bottom: 64px;
    right: 32px;
    text-align: right;
}
nav .content a {
    display: block;
    color: #fff;
    font-size: 48px;
    text-decoration: none;
}
nav .content a:hover {
    text-decoration: underline;
}
nav .content .contact-info {
    color: #fff;
    margin-top: 64px;
}
nav .content .contact-info a{
    display: inline-block;
    margin-left: 16px;
    font-size: 16px;
    text-decoration: underline;
}
nav .bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.9;
    z-index: -1;
    background: #728C73;
    user-select: none;
}

#hero {
    padding: 60px 120px;
    position: relative;
}
#hero h1 {
    font-size: 32px;
    display: none;
}
#hero img {
    width: 270px;
    height: auto;
}
#hero h1,
#hero h2 {
    margin-top: 0;
    color: #728C73;
    margin-left: -5px;
}
#hero h2 {
    font-size: 118px;
    width: 550px;
    line-height: 96px;
    margin: 48px 0 70px 0;
}
@keyframes softFloat {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-12px);
  }
}
#hero .go-down {
    position: absolute;
    left: 50%;
    animation: softFloat 3s ease-in-out infinite;
}

#team {
    min-height: 70vh;
    padding: 12vh 0 5vh 0;
    /*box-shadow: 0px 4px 87px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 4px 87px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 4px 87px 0px rgba(0,0,0,0.1); */
}
#team > div {
    width: 80%;
    margin: auto;
    background: #fff;
    min-height: 550px;
    overflow: hidden;
    display: flex;
}
#team > div:first-child {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom: 2px solid #EEE;
}
#team > div:last-child {
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
}
#team .col-text {
    padding: 70px;
    flex-basis: 60%;
}
#team h2 {
    font-size: 96px;
    color: #211D1D;
    margin: 0 0 32px 0;
}
#team p,
#team li {
    margin: 0;
    font-size: 18px;
    color: #483519;
}
#team .col-img {
    background-size: cover;
    background-position: center;
    flex-basis: 40%;
}
#team > div:first-child .col-img {
    background-image: url('../assets/tres.jpg');
}
#team > div:last-child .col-img {
    background-image: url('../assets/house1.jpeg');
}
#solutions h2 {
    font-size: 96px;
    color: #483519;
    text-align: center;
    margin-top: 0;
    margin-bottom: 16px;
}
#solutions p {
    font-size: 20px;
    width: 50%;
    margin: auto auto 64px auto;
    color: #6E664F;
    text-align: center;
}
#solutions ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2%;
    justify-content: center;
}
#solutions ul li {
    flex-basis: 45%;
    list-style-type: none;
    width: 48%;
    font-size: 40px;
    margin-bottom: 24px;
    color: #6E664F;
    text-align: center;
    padding: 24px;
}

#how h2 {
    font-size: 96px;
    color: #211D1D;
    text-align: center;
}
#how .container {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
}
#how .container div {
    flex-basis: calc(33.333% - 4px - 48px);
    padding: 24px;
    min-height: 400px;
}
#how .container div > span {
    font-size: 90px;
    color: #728C73;
}
#how .container div > h3 {
    color: #483519;
    font-size: 32px;
    margin-bottom: 4px;
}
#how .container div > p {
    font-size: 16px;
    color: #728C73;
}
#how .container div:nth-child(2),
#how .container div:nth-child(5) {
    border-left: 2px solid #728C73;
    border-right: 2px solid #728C73;
}
#how .container div:nth-child(1),
#how .container div:nth-child(2),
#how .container div:nth-child(3) {
    border-bottom: 2px solid #728C73;
}

#founder {
    padding: 64px 0;
    background-color: #728C73;
    background-image: url('/assets/lines-bg.png');
    background-size: cover;
}
#founder .content {
    width: 60%;
    margin: auto;
}
#founder h3,
#founder p {
    color: #FFF;
}
#founder h3 {
    font-size: 64px;
    margin-bottom: 0;
}
#founder p {
    font-size: 20px;
    line-height: 36px;
    margin-top: 0;
}
#founder .center {
    text-align: center;
}
#founder a {
    display: inline-block;
    background: #FFF;
    border: 0;
    color: #728C73;
    font-size: 20px;
    padding: 12px 32px;
    cursor: pointer;
}

footer {
    background: #F4FFF5;
    text-align: center;
    padding: 100px 0 50px 0;
}
footer h1 {
    display: none;
}
footer .sm {
    margin: 32px auto;
}
footer .sm a {
    margin: 0 32px;
}
footer .legal {
    font-size: 16px;
    color: #BFB598;
}
footer .zac {
    margin-top: 32px;
    color: #BBB;
    font-size: 12px;
}

@media only screen and (max-width: 1000px) {
    #team {
        padding-bottom: 4vh;
    }
    #team > div {
        flex-direction: column;
    }
    #team > div:last-child {
        display: flex;
        flex-direction: column-reverse;
    }
    #team .col-text {
        padding: 20px;
    }
    #team h2 {
        font-size: 50px;
        margin-bottom: 16px;
    }
    #team .col-img {
        min-height: 300px;
        flex-grow: 1;
    }
    #solutions h2 {
        font-size: 42px;
    }
    #solutions p {
        font-size: 14px;
        width: 80%;
    }
    #solutions ul {
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        padding-left: 0;
    }
    #solutions ul li {
        width: 80%;
        flex-basis: 100%;
        font-size: 28px;
        text-align: center;
        padding: 8px;
    }
    #how .container div {
        flex-basis: calc(50% - 52px);
    }
    #how .container div:nth-child(2),
    #how .container div:nth-child(5) {
        border-left: none;
        border-right: none;
    }
    #how .container div:nth-child(1),
    #how .container div:nth-child(3),
    #how .container div:nth-child(5) {
        border-right: 2px solid #728C73;
    }
    #how .container div:nth-child(1),
    #how .container div:nth-child(2),
    #how .container div:nth-child(3),
    #how .container div:nth-child(4) {
        border-bottom: 2px solid #728C73;
    }

    #founder .content {
        width: 80%;
    }
}
@media only screen and (max-width: 768px) {
    nav {
        width: 80%;
        right: -80%;
    }
    nav:hover {
        right: -80%;
    }
    nav.opened,
    nav.opened:hover {
        right: 0;
    }
    nav button {
        bottom: unset;
        width: 60px;
        height: 60px;
        left: -60px;
    }

    #hero {
        padding: 60px 24px;
        text-align: center;
    }
    #hero h1 {
        font-size: 24px;
    }
    #hero h2 {
        font-size: 72px;
        line-height:78px;
        margin: 64px auto;
        width: 100%;
    }
    #hero a {
        display: block;
    }
    #team h2 {
        font-size: 32px;
    }
    #team p {
        font-size: 14px;
    }
    #solutions ul li {
        font-size: 20px;
    }
    #how h2 {
        font-size: 50px;
    }
    #how .container {
        width: 100%;
    }
    #how .container div {
        min-height: 300px;
    }
    #how .container div > span {
        font-size: 60px;
    }
    #how .container div > h3 {
        font-size: 18px;
    }
    #how .container div > p {
        font-size: 14px;
    }

    #founder h3 {
        font-size: 40px;
    }
    #founder p {
        font-size: 18px;
        margin-bottom: 16px;
    }
}

@media only screen and (max-width: 500px) {
    #how .container div {
        flex-basis: calc(100% - 52px);
        min-height: unset;
        border-right: none !important;
        border-left: none !important;
        border-bottom: 2px solid #728C73;
    }
    #how .container div:last-child {
        border-bottom: none;
    }
    #how .container div > h3 {
        font-size: 32px;
        margin-top: 6px;
    }
    #how .container div > p {
        font-size: 18px;
    }
    #founder h3 {
        font-size: 24px;
        margin-bottom: 16px;
    }
    #founder p {
        font-size: 14px;
        line-height: 24px;
    }
}
