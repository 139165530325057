#carousel  {
    background: #fff;
}
#carousel h2 {
    font-size: 96px;
    text-align: center;
    color: #211D1D;
}
#carousel h3 {
    font-size: 50px;
    text-align: center;
    color: #728C73;
}
#carousel .image-container {
    min-height: 90vh;
    background-size: contain;
    background-color: #000;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}
#carousel .title-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    background-image: url('/assets/black-gradient.png');
    background-repeat: repeat-x;
    background-size: auto 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 5%;
}
#carousel .title-container h4 {
    color: #FFF;
    font-size: 28px;
    flex-grow: 1;
}
#carousel .title-container button {
    border: none;
    background: none;
}
#carousel .image-nav {
    background: #000;
    padding: 0 5%;
}
#carousel .image-nav button {
    display: inline-block;
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
    margin: 32px 16px;
    border-radius: 10px;
    overflow: hidden;

    transition: transform 0.3s ease;
}
#carousel .image-nav button.selected {
    transform: translateY(-10px);
    border: 1px solid #FFF;
}


@media only screen and (max-width: 992px) {
    #carousel  {
        padding-top: 40px;
    }
    #carousel h2 {
        font-size: 40px;
    }
    #carousel h3 {
        font-size: 24px;
        margin-bottom: 64px;
    }
    #carousel .image-container {
        min-height: 70vh;
        background-size: cover;
    }
    #carousel .title-container {
        padding: 8px 0;
        height: 140px;
    }
    #carousel .title-container h4 {
        font-size: 16px;
        margin: 0;
    }
    #carousel .title-container button {
        padding: 8px;
    }
    #carousel .image-nav {
        padding-bottom: 32px;
    }
    #carousel .image-nav button {
        margin: 8px;
    }
    #carousel .image-nav button.selected {
        transform: unset !important;
    }
}
