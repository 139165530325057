#testimonials {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    width: 95%;
}
#testimonials .col-title {
    text-align: center;
}
#testimonials h2 {
    font-size: 64px;
    color: #728C73;
}
#testimonials .col-testimonials {
    padding: 5% 0 5% 5%;
    max-height: 70vh;
    overflow-y: scroll;
    flex-basis: 50%;
}

#testimonials .testimonial {
    background-color: #728C73;
    background-image: url('/assets/quote.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 150px auto;
    border-radius: 22px;
    padding: 40px;
    color: #FFF;
    margin-bottom: 40px;
}
#testimonials .testimonial h6,
#testimonials .testimonial p{
    margin: 0;
    font-size: 16px;
}
#testimonials .testimonial h6 {
    margin-top: 32px;
    font-size: 18px;
}


@media only screen and (max-width: 1000px) {
    #testimonials {
        flex-direction: column;
        justify-content: center;
        margin: auto;
        width: 80%;
        padding: 0 10%;
    }
    #testimonials .col-testimonials {
        padding: 0;
    }
    #testimonials .testimonial {
        width: 80%;
        margin: 40px auto;
    }
}

@media only screen and (max-width: 600px) {
    #testimonials {
        width: 100%;
        padding: 0;
    }
    #testimonials h2 {
        font-size: 48px;
    }
    #testimonials .testimonial {
        padding: 20px;
    }
}
